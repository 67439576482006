<template>
	<div>
		<div class="container mt-5 pt-4" v-if="$store.getters.usuario.cod_perfil == 4">
			<div class="row">
				<div class="col d-flex  justify-content-end">
					<b-button variant="outline-secondary" :to="{name:'usuario'}"><b-icon-person></b-icon-person> Novo usuário</b-button>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<b-card class="mt-3" :header="'Lista de usuários'">
						<table class="table table-hover table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>Nome</th>
									<th>Email</th>
									<th>Designação</th>
									<th>Ações</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="row in usuarios.rows" :key="row.cod_admin">
									<td v-html="row.cod_admin"></td>
									<td v-html="row.nome"></td>
									<td v-html="row.email"></td>
									<td v-html="row.perfil + (row.orgao ? ' / ' + row.orgao : '')"></td>
									<td>
										<b-nav-item-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
											<template v-slot:button-content>
												<b-icon-list></b-icon-list>
											</template>
											<b-dropdown-item :to="{name: 'usuario', params: { id: row.cod_admin }}">Editar</b-dropdown-item>
											<b-dropdown-item href="#">Desabilitar</b-dropdown-item>
											<b-dropdown-item href="#">Excluir</b-dropdown-item>
										</b-nav-item-dropdown>      
									</td>
								</tr>
							</tbody>
						</table>
						
						<div class="col">
							<nav aria-label="">
								<ul class="pagination">
									<li :class="n == usuarios.pagina ? 'page-item active' : 'page-item'" aria-current="page" v-for="n in usuarios.total_paginas" v-bind:key="n" v-on:click="pager(n)">
										<span class="page-link btn btn-outline-dark">
											{{n}}
											<span class="sr-only">(current)</span>
										</span>
									</li>
								</ul>
							</nav>
						</div>
					</b-card>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Usuarios",
		data(){
			return {
				usuarios: []
			}
		},
		created: function() {
			if (this.$store.getters.usuario.cod_perfil != 4) {
                window.location = '/'
            }
		},
		mounted: function() {
			this.$http.post('buscaAdmins.php').then(
				response => {
					this.usuarios = response.data 
				}
			)
		},
		methods: {
		}
	}
</script>
