<template>
	<div class="login">
		<div class="container">
			<h1>BURACO<span>ZERO</span></h1>
			<div class="row">
				<div class="col-sm-4">
					<form :class="'form-signin '+validate" ref="form">
						<h6 class="text-center mb-3">ACESSAR</h6>
						<div class="form-label-group">
							<input type="email" id="inputEmail" class="form-control" placeholder="Email" v-model="payload.email" required>
							<label for="inputEmail">Email</label>
						</div>

						<div class="form-label-group">
							<input type="password" id="inputPassword" class="form-control" placeholder="Senha" v-model="payload.senha" required>
							<label for="inputPassword">Senha</label>
						</div>

						<div class="checkbox mb-3">
							<label>
								<input type="checkbox" value="remember-me"> Lembrar de mim
							</label>
						</div>
						<button class="btn btn-warning btn-block" type="button" v-on:click="login">Entrar</button>
						<p class="mt-5 mb-3 text-muted text-center">
							<a class="text-muted" href="https://dubbox.com.br/" target="_blank">Dubbox Tech</a> &copy; {{  new Date() | moment('Y') }}
						</p>
					</form>
				</div>
			</div>
			<div class="logos">
				<a href="http://www.df.gov.br/" target="_blank">
					<img src="http://www.df.gov.br/wp-conteudo/themes/templategdf/img/marca-do-governo/Marca%20Horizontal.png" alt="">
				</a>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: "Login",
		data(){
			return {
				payload: {},
				validate: ''
			}
		},
		methods: {
			login(){
				this.validate = 'needs-validation was-validated'
				if (!this.payload.email || !this.payload.senha) {
					this.$vToastify.error({ title: "Campos obrigatórios", body: "Insira seu email e senha" });
					return
				}
				this.$http.post('loginAdmin.php', this.payload).then(
					response => {
						if (!response.data.success) {
							this.$vToastify.error({ title: "Erro", body: response.data.error });
							return
						}
						localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
						localStorage.setItem('auth', true);
						location.href = '/'
						return
					}
				)
			}
		}
	}
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Rubik:900&display=swap');
	.login{
		background-image: url(../assets/login-bg.png);
		height: 100vh !important;
		background-size: cover;
		display: flex;
		align-items: center;
		position: relative;
	}

	.login h1{
		font-family: 'Rubik' ;
		color: #fff;
	}

	.login h1 span{
		color: var(--laranjado) !important;
	}

	.form-signin{
		border-top: 5px solid #F39200;
		background: #f7f7f7c7;
		padding: 30px 20px 10px;
		margin-top: 30px;
	}

	.form-signin .form-control{
		border-radius: 0;
	}

	.form-label-group {
		position: relative;
		margin-bottom: 1rem;
	}

	.form-label-group > input,
	.form-label-group > label {
		height: 3.125rem;
		padding: .75rem;
	}

	.form-label-group > label {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		margin-bottom: 0; /* Override default `<label>` margin */
		line-height: 1.5;
		color: #495057;
		pointer-events: none;
		cursor: text; /* Match the input under the label */
		border: 1px solid transparent;
		border-radius: .25rem;
		transition: all .1s ease-in-out;
	}

	.form-label-group input::-webkit-input-placeholder {
		color: transparent;
	}

	.form-label-group input:-ms-input-placeholder {
		color: transparent;
	}

	.form-label-group input::-ms-input-placeholder {
		color: transparent;
	}

	.form-label-group input::-moz-placeholder {
		color: transparent;
	}

	.form-label-group input::placeholder {
		color: transparent;
	}

	.form-label-group input:not(:placeholder-shown) {
		padding-top: 1.25rem;
		padding-bottom: .25rem;
	}

	.form-label-group input:not(:placeholder-shown) ~ label {
		padding-top: .25rem;
		padding-bottom: .25rem;
		font-size: 12px;
		color: #777;
	}

	/* Fallback for Edge
	-------------------------------------------------- */
	@supports (-ms-ime-align: auto) {
		.form-label-group > label {
			display: none;
		}
		.form-label-group input::-ms-input-placeholder {
			color: #777;
		}
	}

	/* Fallback for IE
	-------------------------------------------------- */
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.form-label-group > label {
			display: none;
		}
		.form-label-group input:-ms-input-placeholder {
			color: #777;
		}
	}

	.login .btn-warning{
		background-color: var(--laranjado);
		color: #fff;
		border-color: var(--laranjado);
	}

	.login .logos{
		position: absolute;
		right: 20px;
		bottom: 20px;
	}

	.login .logos img{
		width: 100px;
	}
</style>