<template>
	<div>
		<div class="container mt-5 pt-4" v-if="$store.getters.usuario.cod_perfil == 4">
			<b-card class="mt-3" :header="''">
				<b-form :class="validate" ref="form">
					<div class="row">
						<div class="col">
							<b-form-group id="input-group-2" label="Nome" label-for="input-2">
								<b-form-input
								id="input-2"
								v-model="form.nome"
								required
								placeholder="Nome"
								></b-form-input>
							</b-form-group>
						</div>

						<div class="col">
							<b-form-group
								id="input-group-1"
								label="Email:"
								label-for="input-1"
								>
								<b-form-input
								id="input-1"
								v-model="form.email"
								name="form.email"
								type="email"
								required
								placeholder="Email"
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col">
							<b-form-group
								label="Senha:"
								>
								<b-form-input
								v-model="form.senha"
								name="form.senha"
								type="password"
								:required="$route.params.id ? false : true"
								placeholder="Senha"
								></b-form-input>
							</b-form-group>
						</div>
					</div>

					<b-form-group label="Perfil" class="radio">
						<b-form-radio v-model="form.cod_perfil" name="cod_perfil" :value="perfil.cod" v-for="perfil in perfis" :key="perfil.cod" @change="changePerfil(perfil.cod)" required>
							{{perfil.label}}
						</b-form-radio>
					</b-form-group>

					<div class="form-group" v-if="form.cod_perfil == 1 || form.cod_perfil == 2">
						<label for="" v-html="titulo_select"></label>
						<v-select v-model="form.codigo" :reduce="option => option.cod" :options="valores_select" required></v-select>
					</div>
					
					<div class="row">
						
					</div>

					

					<b-button v-on:click="onSubmit" variant="primary">Salvar</b-button>
					<!-- <b-button type="reset" variant="danger">Reset</b-button> -->
				</b-form>
			</b-card>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Usuario",
		data() {
			return {
				form           : {},
				show           : true,
				ras            : [],
				perfis         : [],
				orgaos         : [],
				validate       : '',
				valores_select : [],
				titulo_select  : ''
			}
		},
		created(){
			if (this.$store.getters.usuario.cod_perfil != 4) {
                window.location = '/'
            }
		},
		mounted(){
			this.$http.post('montaFormUsuario.php').then(
				response => {
					response.data.ra.forEach((item) => {
                        this.ras.push({
							cod   : item.cod_ra,
							label : item.nome
                        })
                    });
                    response.data.perfil.forEach((item) => {
                        this.perfis.push({
							cod   : item.cod_perfil,
							label : item.descricao
                        })
                    });
                    response.data.orgao.forEach((item) => {
                        this.orgaos.push({
							cod   : item.cod_orgao,
							label : item.descricao
                        })
                    });
				}
            )

			if (this.$route.params.id) {
				this.$http.post('buscaAdmins.php', { cod_admin:this.$route.params.id }).then(
					response => {
						this.form = response.data.admin

						if (this.form.cod_perfil == 1) {
							this.valores_select = this.ras
							this.titulo_select  = 'Defina a RA'
						} else if (this.form.cod_perfil == 2) {
							this.valores_select = this.orgaos
							this.titulo_select  = 'Defina o Orgão'
						}else{
							this.valores_select = []
						}
					}
				)
			}
		},
		methods: {
			onSubmit(evt) {
				evt.preventDefault()
				this.validate = 'needs-validation was-validated'

				if(!this.$refs['form'].checkValidity()){
					return
				}

				if ((this.form.cod_perfil == 1 || this.form.cod_perfil == 2) && !this.form.codigo){
					let body = this.form.cod_perfil == 1 ? "Defina uma RA!" : "Defina um orgão!"
					this.$vToastify.warning({ title: "Atenção", body: body });
					return
				}

				this.$http.post('Usuario.php', this.form).then(
					response => {
						response
						if (!response.data.success) {
							this.$vToastify.warning({ title: "Erro!", body: response.data.error });
							return
						}

						let tipo = 'cadastrado'
						if (this.$route.params.id) {
							tipo = 'editado' 
						}
						this.$vToastify.success({ title: "Tudo certo!", body: `Usuário ${tipo} com sucesso` });
						this.$router.push({ name: "usuarios"})
					}
				)
			},
			onReset(evt) {
				evt
			},
			changePerfil(cod){
				// this.form.codigo = null
				if (cod == 1) {
					this.valores_select = this.ras
					this.titulo_select  = 'Defina a RA'
				} else if (cod == 2) {
					this.valores_select = this.orgaos
					this.titulo_select  = 'Defina o Orgão'
				}else{
					this.valores_select = []
				}
			}
		}
	}
</script>

<style scoped>
	.radio .bv-no-focus-ring{
		display: flex;
	}
</style>
