import Vue from 'vue'
import VueRouter from 'vue-router'
import GoogleMap from '@/components/GoogleMap'
import Dashboard from '@/components/Dashboard'
import Denuncias from '@/components/Denuncias'
import Usuarios from '@/components/Usuarios'
import Usuario from '@/components/Usuario'
import Login from '@/components/Login'
import NovaDenuncia from '@/components/NovaDenuncia'
import Relatorios from '@/components/Relatorios'

// import { store } from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
	routes: [
		{
			path: '/',
			name: 'home',
			component: GoogleMap,
			beforeEnter(to, from, next) {
				next()
			}
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: Dashboard
		},
		{
			path: '/solicitacoes',
			name: 'denuncias',
			component: Denuncias
		},
		{
			path: '/login',
			name: 'login',
			component: Login
		},
		{
			path: '/usuarios',
			name: 'usuarios',
			component: Usuarios
		},
		{
			path: '/usuario/:id?',
			name: 'usuario',
			component: Usuario
		},
		{
			path: '/nova-solicitacao',
			name: 'nova-solicitacao',
			component: NovaDenuncia
		},
		{
			path: '/relatorios',
			name: 'relatorios',
			component: Relatorios
		}
	]
})

router.beforeEach((to, from, next) => {
	console.log()
	if (!localStorage.getItem('auth') && to.name !== 'login') {
		window.location = '/login'
		return
	}
	next()
})

export default router
