<template>
	<div>
		<div class="container mt-5 pt-5">
			<b-tabs content-class="mt-3"  v-if="$store.getters.usuario.cod_perfil == 4">
				<b-tab title="Dados por RA" active>
					
					<div class="row">
						<div class="col">
							<highcharts :options="chart1"></highcharts>
						</div>
					</div>
					
					<div class="row">
						<div class="col">
							<carousel :perPage="perPage" :navigationEnabled="true" :paginationEnabled="false">
								<slide v-for="(chat, key) in chartsStatusRa" v-bind:key="key">
									<div class="p-2">
										<highcharts :options="chat"></highcharts>
									</div>
								</slide>
							</carousel>
						</div>
					</div>

				</b-tab>
				<b-tab title="Dados por Orgão"></b-tab>
				<b-tab title="Dados por usuário"></b-tab>
			</b-tabs>
			<h3 class="text-center" v-else>Em breve</h3>
		</div>	
	</div>
</template>

<script>
	export default {
		name: "Dashboard",
		data(){
			return {
				chart1: {
					chart: {
						type: 'column',
					},
					title: {
						text: 'Denúncias por RA'
					},
					xAxis: {
						categories: []
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Denúncias'
						}
					},
					plotOptions: {
						column: {
							stacking: 'normal'
						}
					},
					legend: false,
					series: []
				},

				chartsStatusRa: [],
				perPage: parseFloat(5)
			}
		},
		created: function() {
			this.getData()
			// this.timeout()
		},
		methods: {
			getStatusColor(status){
				if(status == 1) {
					return '#FF0025'
				}
				if(status == 2) {
					return '#F2FF00'
				}
				if(status == 3) {
					return '#048B52'
				}
				if(status == 4) {
					return '#555555'
				}
				if(status == 5) {
					return '#000000'
				}
				return '#fff' 
			},
			getData(){
				this.$store.commit('changeLoading', true)
				this.chart1.xAxis.categories = []
				this.chart1.series = []
				this.chartsStatusRa = []
				this.$http.post('Dashboard.php').then(
					response => {
						response.data.ra.forEach((item, key) => {


							this.chart1.xAxis.categories.push(item.ra)
							this.chart1.series.push({
								data: [{x:key, y: parseInt(item.total)}],
								name: item.nome
							})
							if (item.total > 0) {

								let dataStatus = []
								item.status.forEach((status) => {
									dataStatus.push({
										name: status.dsc_status,
										y: parseFloat(status.total),
										color: this.getStatusColor(status.cod_status)

									})
								})


								this.chartsStatusRa.push({
									chart: {
										plotBackgroundColor: null,
										plotBorderWidth: null,
										plotShadow: false,
										type: 'pie'
									},
									title: {
										text: item.nome + ' ' + item.ra 
									},
									tooltip: {
										pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
									},
									plotOptions: {
										pie: {
											allowPointSelect: true,
											cursor: 'pointer',
											dataLabels: {
												enabled: false
											},
											showInLegend: false
										}
									},
									series: [{
										name: 'Denúncias',
										colorByPoint: true,
										data: dataStatus
									}]
								})

							}
						})
					}
				)
				this.$store.commit('changeLoading', false)
			},
            timeout() {
                setTimeout(() => {
                    this.getData()
                    this.timeout();
                }, 30000);
            },
		}
	}
</script>