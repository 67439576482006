<template>
    <div>
        <gmap-map
            :center="center"
            :zoom="zoom"
            style="width:100%;"
            ref="map"
            :map-type-id="mapTypeId"
            :options="options"
            @drag="dragMap"
            @zoom_changed="ChangeZoom"
            >
            <gmap-marker :position="makerPosition" :icon="icon"></gmap-marker>
        </gmap-map>
        <div class="salvar-solicitacao">
            <button class="btn btn-warning px-5" @click="nova">Solicitar serviço</button>
        </div>
        <b-modal id="nova-solicitacao" size="lg" centered title="Nova solicitação">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="">Descrição</label>
                        <textarea v-model="novaSolicitacao.descricao" class="form-control"></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4" v-for="(image, key) in novaSolicitacao.midias" :key="key">
                    <picture class="img-thumbnail" :style="'background-image: url('+image.url_midia+')'">
                        <div class="close text-danger" v-if="key != 0" @click="deleteImage(key)"><b-icon-trash></b-icon-trash></div>
                    </picture>
                </div>
            </div>
            <div class="row">
                <div class="col mt-3">
                    <button class="btn btn-outline-secondary" @click="$refs.file.click()">Mais imagens</button>
                    <input type="file" @change="onFileChange" multiple v-show="false" ref="file">
                </div>
            </div>
            <template v-slot:modal-footer>
                <div class="w-100 text-right">
                    <button class="btn btn-outline-secondary mr-2" @click="$bvModal.hide('nova-solicitacao')">Cancelar</button>
                    <button class="btn btn-outline-success" @click="enviarSolicitacao">Salvar</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "GoogleMap",
        data() {
            return {
                center        : { lat: -15.7955504, lng: -47.8688563 },
                makerPosition : { lat: -15.7955504, lng: -47.8688563 },
                zoom          : 11,
                markers       : [],
                newMarkers    : [],
                places        : [],
                currentPlace  : null,
                currentMidx   : null,
                options       : {
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        mapTypeIds: [
                            'HYBRID', 'ROADMAP', 'SATELLITE', 'TERRAIN'
                        ]
                    }
                },
                mapTypeId: "satellite",
                filtrosNaturais: {all: true},
                novaSolicitacao: {
                    latitude: -15.7955504,
                    longitude: -47.8688563,
                    midias: []
                },
                icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5AIcFCwFc3rc/gAABFdJREFUWMPt2F2I1FUYx/HP+c/srtpqWhKZ2ottrmVUoBEWRvQCWVd1ExEEBdFt0BtIRi8XdVFQBl1UYF2YSBddVBR0oZEJZi8Xmtr6tkUq9p7p6o4z/9PFOWOzbwbrLsxFD5ydmZ05//me5/c8v3P+E4wzokvhLIpX8AAq473WGHESH1XHP7+AOVg2CXDQgWVnABigSpwMODiIF88A8FTESYDbgZWUZyLxpMUGPMngVrpSIbVJ1LGW+BDFVqai0C4ZPILXiS8Tfk2sBHvbAvAPPE3xBmWtCdaMdpC4igr17Aa1pse2DeB0PE/xLGbTiROinrYBbEI+gtXo4SwUooVtA0jaOe7Fm8RlzEZsK8Bm3IQ1/HI39aIduni06MVqqvPbMYPNmItVE5nBI9IGPxERJLfePJGAm/DYBF6vQTg0kYB/YucEXg8mdKsL6c/uCQVs5yb5H/B/wLaItgc8rc1ES3ABdkkuEk+9M/acy1pehfxYIozLgoYARoukU89ghjiCv1AUOBvd0t3Mz5IxjxXzMAXH8DeVo5QZfujigj3/DRgtxtG80uOoBhozsJhwNbEXPdIGfg6ewPoxrhnwDG4jHsCPxN1Zhm+wn+oJGoiintNCVqOFqOXMmZqAGitwKxZIJ8fWTJfSufx00Y0L81iW/zeAw4TtND4kbKCxl0qZGAj6RstgRCXQWEp8GHfi/JbPnMTv+BX9+IGwY2y2MlJ8nLWch4tyeUzHJXncQdxJZR3hHRoHCKJ/q7YVsJPGfViVJ5OOOn34Cl8maUI/cQCDdNSa964jo4KwlvgeZRdmEnpxbR7NzrsSzxFvoVhJxxbmimYKvmsFDPfjhSzlCXyBd7GJch+VekpGa3E3/kPhWE+LDAPSfe9+yk+oTqe8HCtwDxbhZrxK/UGm7hhePVU8muF+w4vENYTfUrIDurBtROqbddOiSkAI+kZ4UGrCTgz8nRXZSlhPXIn7cB3xLrp2cmzI/ALz8/N9xPcTHKkXCKPANb+WeEwqfmmBJ0c1yCRZTXKIgBgpduH9rBrModYx3IYKfJ6fX0N4CTdiWjOD0QLRFWMAlgdT1r1EeCs7wSifvCwprkvqObNpPIhn03cZxNeE2ojFRQuvJ66Wipdkwp/iwzSp0U/HyWZGh+cmSVerEMcozGZvhm7iItyAu6SGmZalWoPHKf6gU7CtFXAepiwhPCV5X3d+7zj2S8f4b7E9vz6aZa0R6lLHlJQVQkVKY1dWYVaGugpXY6FkPc0zwAG8jdcIh9NCvh9SUiG6PK+yMVPywHuxHDOGpaKWwX7x71Z3IstTz6nskn63OAfn5ccpRhr9QXxAXEd9M5UGpWD/SI1SfSyVLK9EZRbxSukOfzkulox7uvFFKTnEIewhbCRupOyjMpjWeDs+E3w/ahHlSultqZmmwtPOpuyR9uHeDDs3j1nSwWFqzt7xPI5Ju85PeezB7rQfN/opaknhiHOxZQyXSPEPkKpM1Pm/JTIAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDItMjhUMjA6NDQ6MDUrMDA6MDDVY1d5AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAyLTI4VDIwOjQ0OjA1KzAwOjAwpD7vxQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII='
            };
        },
        mounted() {
            this.geolocate();
        },
        created(){
            
        },
        methods: {
            dragMap(){
                this.$refs.map.$mapPromise.then(
                    (map)  =>{
                        this.makerPosition = {
                            lat: map.center.lat(),
                            lng: map.center.lng()
                        }
                        this.novaSolicitacao.latitude    = map.center.lat()
                        this.novaSolicitacao.longitude   = map.center.lng()
                });
            },
            ChangeZoom(){
                this.$refs.map.$mapPromise.then(
                    (map)  =>{
                        this.makerPosition = {
                            lat: map.center.lat(),
                            lng: map.center.lng()
                        }
                        this.novaSolicitacao.latitude    = map.center.lat()
                        this.novaSolicitacao.longitude   = map.center.lng()
                });
            },
            onFileChange(e){
                e.target.files.forEach(item => {
                    if (item.type == 'image/png' || item.type == 'image/jpg' || item.type == 'image/jpeg') {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            this.novaSolicitacao.midias.push({
                                type: 'image',
                                url_midia: e.target.result
                            })
                        };
                        reader.onerror = function(error) {
                            console.log(error);
                        };
                        reader.readAsDataURL(item); 
                    }else{
                        console.log(item.type)
                    }
                })
            },
            deleteImage(key){
                this.novaSolicitacao.midias.splice(key, 1);
            },
            updateCoordinates(i){
                this.novaSolicitacao.latitude    = i.latLng.lat()
                this.novaSolicitacao.longitude   = i.latLng.lng()

                this.center = {
                        lat: i.latLng.lat(),
                        lng: i.latLng.lng()
                    };
            },
            setPlace(place) {
                this.currentPlace = place;
            },
            geolocate: function() {
                navigator.geolocation.getCurrentPosition(position => {
                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    this.novaSolicitacao.latitude  = position.coords.latitude
                    this.novaSolicitacao.longitude = position.coords.longitude
                });
            },

            nova(){
                if (this.$store.getters.usuario.cod_perfil != 5) {
                    return
                }

                this.$bvModal.show('nova-solicitacao')
                
                this.novaSolicitacao.cod_usuario = this.$store.getters.usuario.cod_admin
                this.novaSolicitacao.ouvidoria   = 1
                this.novaSolicitacao.referencia  = null

                this.novaSolicitacao.midias = []
                this.$request('https://maps.googleapis.com/maps/api/staticmap?zoom=18&size=600x400&maptype=satellite&markers=color:green%7Clabel:B%7C'+this.novaSolicitacao.latitude+','+this.novaSolicitacao.longitude+'&key=AIzaSyC9fJjO3s62tWXVyZHn7_UHc-_seSfBecY', {
                        responseType: 'arraybuffer' 
                    }).then(
                        response => {
                            this.novaSolicitacao.midias.push({
                                type: 'gmaps',
                                url_midia: this._imageEncode(response.data)
                            })
                        }
                    )

                // 
                navigator.geolocation.getCurrentPosition(position => {
                    this.novaSolicitacao.latitude_usuario  = position.coords.latitude
                    this.novaSolicitacao.longitude_usuario = position.coords.longitude
                });

                this.$request('https://maps.googleapis.com/maps/api/geocode/json?&latlng='+this.novaSolicitacao.latitude+','+this.novaSolicitacao.longitude+'&key=AIzaSyC9fJjO3s62tWXVyZHn7_UHc-_seSfBecY').then(
                    response => {
                        this.novaSolicitacao.endereco = response.data.results[0].formatted_address

                        response.data.results[0].address_components.forEach((item) =>
                            {
                                if (item.types.includes('sublocality')) {
                                    this.novaSolicitacao.bairro = item.long_name
                                }  
                                if (item.types.includes('administrative_area_level_4') && !this.novaSolicitacao.bairro) {
                                    this.novaSolicitacao.bairro = item.long_name
                                }
                                if (item.types.includes('administrative_area_level_2')) {
                                    this.novaSolicitacao.cidade = item.long_name
                                } 
                                if (item.types.includes('administrative_area_level_1')) {
                                    this.novaSolicitacao.estado = item.long_name
                                } 
                                if (item.types.includes('postal_code')) {
                                    this.novaSolicitacao.cep = item.long_name
                                }   
                            }
                        )
                    }
                )

                // this.$vToastify.warning({ title: "Atenção", body: diff + " novas solicitações"});

            },
            _imageEncode (arrayBuffer) {
                let u8 = new Uint8Array(arrayBuffer)
                u8
                let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
                let mimetype="image/png"
                return "data:"+mimetype+";base64,"+b64encoded
            },
            enviarSolicitacao(){
                if(!this.novaSolicitacao.descricao){
                    this.$vToastify.warning({ title: "Atenção", body: "Defina uma descrição!"});
                    return
                }
                this.$store.commit('changeLoading', true)
                this.$http.post('salvarDenuncia_new.php', this.novaSolicitacao).then(
                    response => {
                        if (response.data.success) {
                            this.$vToastify.success({ title: "Tudo certo!", body: "Solicitação enviada com sucesso!"});
                            this.$router.push({ name: "home"})
                            this.$store.commit('changeLoading', false)
                            return
                        }
                        this.$vToastify.warning({ title: "Atenção", body: response.data.error});
                        this.$store.commit('changeLoading', false)
                    }
                )   
            }
        }
    };

</script>

<style scoped>
    #app{
        max-height: 100vh;
        height: 100vh !important;
        top: 0;
        z-index: -1;
    }

    #app .vue-map-container{
        height: 100vh !important;
    }

    picture{
        height: 150px;
        float: left;
        min-width: 100%;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
</style>