import Vue from 'vue'
import App from './App.vue'
import router from './router'
//components
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue/dist/bootstrap-vue.esm';
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues
import Axios from 'axios';
import VueToastify from "vue-toastify";
import VueLodash from 'vue-lodash'
import random from 'lodash/random'
import map from 'lodash/map'
import difference from 'lodash/difference'
import { store } from './store'
import HighchartsVue from 'highcharts-vue'
import VueCarousel from 'vue-carousel';
import vSelect from 'vue-select'
import VueHtmlToPaper from 'vue-html-to-paper';


Vue.use(HighchartsVue)
Vue.use(VueCarousel);
Vue.use(VueLodash, { name: 'custom', lodash: { map, random, difference } })

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}
 
Vue.use(VueHtmlToPaper, options);
// Import the styles directly. (Or you could add them via script tags.)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);

Vue.use(BootstrapVueIcons)
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
Vue.component('GmapCluster', GmapCluster)
Vue.use(VueToastify);
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyC9fJjO3s62tWXVyZHn7_UHc-_seSfBecY',
		libraries: "places"
	},
	installComponents: true
})

Vue.use(require('vue-moment'));


Vue.config.productionTip = false

Vue.prototype.$http = Axios.create({
    baseURL: 'https://apiburacozero.dubbox.com.br/api/',
    timeout: 50000,
    headers: {
		"Authorization": "Basic NTU2NTJmOGI1ZDEwNmFoZDczbmQ5MW5hMDFhODk0NTk0OGRoczczZzgzaGQ4NzQ3MzY0OGowM2I2MjluZDoxMjA4Mjk4NDM5",
		"Content-Type": "application/x-www-form-urlencoded"
	}
});

Vue.prototype.$request = Axios.create()

new Vue({
	render: h => h(App),
	router: router,
	store: store
}).$mount('#app')

