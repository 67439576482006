<template>
    <div id="app">
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" v-if="$route.name != 'login'">
            <a href="" class="navbar-brand mr-0 mr-md-2">BURACO<span>ZERO</span></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto align-items-center">
                    <li class="nav-item active pl-4" v-if="$route.name == 'home'">
                        <b-button class="nav-link btn btn-warning" v-b-modal.filtros-mapa>
                            Filtros <b-icon-filter style="color:#fff"></b-icon-filter> 
                        </b-button>
                    </li>
                    <li class="nav-item pl-3" v-if="$route.name == 'home' && $store.getters.usuario.cod_perfil == 4">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" @change="$store.commit('changeShapes', !$store.getters.shapes)">
                            <label class="custom-control-label" for="customSwitch1">Mostrar limites das RA's</label>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                    <li class="nav-item active" v-if="false">
                        <a class="nav-link" href="#"><b-icon-bell style="color:#fff"></b-icon-bell></a>
                    </li>
                    <b-nav-item-dropdown :text="'Olá '+ $store.getters.usuario.nome || ''" right>
                        <!-- <b-dropdown-item class="text-center" :to="{name: 'usuario', params: {id:$store.getters.usuario.cod_admin}}">Seu perfil <b-icon-person></b-icon-person></b-dropdown-item> -->
                        <b-dropdown-item class="text" @click="$bvModal.show('configuracoes')" v-if="$store.getters.usuario.cod_perfil == 4">Configurações <b-icon-gear></b-icon-gear></b-dropdown-item>
                        <b-dropdown-item class="text" v-on:click="logout" href="#">Sair <b-icon-box-arrow-right></b-icon-box-arrow-right></b-dropdown-item>
                    </b-nav-item-dropdown>                    
                </ul>
            </div>
        </nav>
        <div :class="!$store.getters.sidebar ? 'd-flex toggled' : 'd-flex'" id="wrapper" ref="wrapper">
            <div class="bg-light" id="sidebar-wrapper"  v-if="$route.name != 'login'">
                <div class="list-group list-group-flush">
                    <router-link  style="color:#fff;" class="list-group-item list-group-item-action h4" :class="$route.name == 'home' ? 'ativo':''" :to="{ name: 'home'}">
                        <b-icon-map></b-icon-map> <span>Mapa</span>
                    </router-link>
                    <router-link  style="color:#fff;" class="list-group-item list-group-item-action h4" :class="$route.name == 'denuncias' ? 'ativo':''" :to="{ name: 'denuncias'}">
                        <b-icon-alert-triangle></b-icon-alert-triangle> <span>Solicitações</span>
                    </router-link>
                    <router-link  style="color:#fff;" class="list-group-item list-group-item-action h4" :class="$route.name == 'dashboard' ? 'ativo':''" :to="{ name: 'dashboard'}">
                        <b-icon-graph-up></b-icon-graph-up> <span>Dashboard</span>
                    </router-link>
                    <router-link style="color:#fff;" class="list-group-item list-group-item-action h4" :class="$route.name == 'relatorios' ? 'ativo':''" :to="{ name: 'relatorios'}">
                        <b-icon-document-spreadsheet></b-icon-document-spreadsheet> <span>Relatórios</span>
                    </router-link>
                    <router-link v-if="$store.getters.usuario.cod_perfil == 4" style="color:#fff;" class="list-group-item list-group-item-action h4" :class="$route.name == 'usuarios' ? 'ativo':''" :to="{ name: 'usuarios'}">
                        <b-icon-people></b-icon-people> <span>Usuários</span>
                    </router-link>
                    <a href="#"  style="color:#fff;" class="list-group-item list-group-item-action h4" v-on:click="$store.commit('changeSidebar', !$store.getters.sidebar)"><b-icon-chevron-left></b-icon-chevron-left></a>
                </div>
            </div>

            <div id="page-content-wrapper">
                <router-view></router-view>
            </div>
        </div>
        <transition name="fade">
            <div class="overlay" v-if="$store.getters.loading">
                <div class="overlay__inner">
                    <div class="overlay__content"><span class="spinner"></span></div>
                </div>
            </div>
        </transition>
        <audio src="./assets/alert.mp3" ref="alerta" muted="muted" style="visibility: hidden;"></audio>

        <b-modal id="configuracoes" size="lg" centered title="Configurações" v-if="$route.name != 'login' && $store.getters.usuario.cod_perfil == 4">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="">Black List </label>
                        <v-select taggable multiple push-tags v-model="$store.getters.configuracoes.blacklist" :noDrop="true" />
                    </div>
                </div>
            </div>
            <template v-slot:modal-footer>
                <div class="w-100 text-right">
                    <button class="btn btn-outline-secondary mr-2" @click="$bvModal.hide('configuracoes')">Cancelar</button>
                    <button class="btn btn-outline-success" @click="salvarConfiguracoes">Salvar</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {},
        data() {
            return {
                sidebar: false,
                filtros: {},
                status: {},
                orgao: {},
                notificacoes: {}
            };
        },
        created(){
            if (!localStorage.getItem('auth')) {
                // window.location = '/login'
            }
        },
        mounted(){
            this.$http.get('Configuracoes.php').then(
                response => {
                    this.$store.commit('changeConfiguracoes', response.data)
                }
            )
        },
        methods: {
            logout(){
                
                localStorage.clear();
                location.href = "/login"
                this.$store.commit('changeUsuario', {})
            },
            salvarConfiguracoes(){
                this.$http.post('Configuracoes.php', this.$store.getters.configuracoes).then(
                    response => {
                        this.$bvModal.hide('configuracoes')
                        if (response.data.success) {
                            this.$vToastify.success({ title: "Tudo certo!", body: "Configurações salvas com sucesso!" });
                            return
                        }

                        this.$vToastify.warning({ title: "Opss!", body: "Ocorreu um erro, tente novamente!" });
                    }
                )
            }
        },
        filters: {
            toList: function (value) {
                return value
            }
        },
        watch:{
            $route (){
                if (!localStorage.getItem('auth')) {
                    window.location = '/login'
                }
            }
        } 
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
    @import url('https://use.fontawesome.com/releases/v5.0.13/css/all.css');
    @import url('https://fonts.googleapis.com/css?family=Rubik:900&display=swap');
    body {
        overflow-x: hidden;
        font-family: 'Source Sans Pro', sans-serif !important;
        background: #F2F4FC !important;
        color: #808495 !important;
    }

    :root{
        --laranjado: #F39200;
    }

    a, a:hover{
        text-decoration: none !important;
    }

    h5, .h5 {
        font-size: 1.25rem;
        color: #808495;
    }

    .navbar-light .navbar-brand{
        font-family: 'Rubik' ;
        color: #fff !important;
    }

    .navbar-light .navbar-brand span{
        color: var(--laranjado);
    }

    .sidebar-heading, .navbar-light, .navbar-light .navbar-nav .nav-link{
        background-color: #444A5B !important;
        color: #fff !important;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #fff;
    }
    .gm-style .gm-style-iw-c{
        border-radius: 0 !important;
    }
    .vue-map .card{
        max-width: 350px;
        overflow: hidden;
        border-radius: 0;
    }
    .card-image figure {
        margin: 0 0 1rem;
        height: 200px;
        width: 350px;
        margin-bottom: 10px;
        background-size: cover;
        background-position: center;
    }


    #sidebar-wrapper {
        position: fixed;
        z-index: 99;
        min-height: calc(100vh - 56px);
        margin-top: 56px;
        margin-left: -15rem;
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;
        background: var(--laranjado) !important;
    }

    #sidebar-wrapper .sidebar-heading {
        padding: 0.875rem 1.25rem;
        font-size: 1.2rem;
    }

    #sidebar-wrapper .list-group {
        width: 15rem;
        
    }

    #sidebar-wrapper .list-group a span{
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;
        font-size: 15px !important;
        color: #fff;
    }

    #page-content-wrapper {
        min-width: 100vw;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: 0;
    }

    #wrapper.toggled #sidebar-wrapper .list-group{
        padding-left: 12rem;
    }

    #wrapper.toggled #sidebar-wrapper .list-group a span{
        display: none;
    }

    #sidebar-wrapper .list-group .list-group-item{
        padding-left: 10px;
        background: transparent;
        border: 0;
        margin-bottom: 0;
    }

    #sidebar-wrapper .list-group .list-group-item.ativo, #sidebar-wrapper .list-group .list-group-item:hover{
         background: #c57804 !important;
    }

    /*#sidebar-wrapper .list-group .list-group-item.ativo a{
        color: #808495;
    }*/

    #sidebar-wrapper .list-group .list-group-item{
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;
        transform: rotate(0);
        line-height: 1;
        display: flex;
        align-items: center;
    }

    #sidebar-wrapper .list-group .list-group-item span{
        padding-left: 15px;
    }

    #wrapper.toggled #sidebar-wrapper .list-group .list-group-item:last-child{
        transform: rotate(-180deg);
    }

    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -12rem;
    }

    .list-group-item{
        background: transparent;
        border: 0;
    }
    .carousel-fade .carousel-item{
        height: 435px;
        display: flex;
        justify-content: center;
        background: #E1E3ED;
    }

    .carousel-fade .carousel-item>img{
        width: auto !important;
        height: 100%;
        max-width: auto !important;
        max-height: 100%;
        margin: auto;
    }

    .overlay {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: #474c5df5;
        z-index: 999999;
    }

    .overlay__inner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .overlay__content {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .spinner {
        width: 75px;
        height: 75px;
        display: inline-block;
        border-width: 2px;
        border-color: rgba(255, 255, 255, 0.05);
        border-top-color: #fff;
        animation: spin 1s infinite linear;
        border-radius: 100%;
        border-style: solid;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    .modal-backdrop{
        opacity: 1 !important;
        background-color: #474c5df5 !important;
    }

    .modal-content{
        border-radius: 0 !important;
    }

    .btn{
        border-radius: 0 !important;
    }

    @media (min-width: 1200px){
        .modal-xl {
            max-width: 90% !important;
        }
    }

    .modal .list-group .list-group-item{
        display: flex;
        align-items: center;
    }

    .modal .badge:empty {
        display: inline-block;
        width: 22px;
        height: 22px;
    }

    g.raphael-group-eJdYdyBt {
        display: none !important;
    }

    .VueCarousel .highcharts-title{
        font-size: 13px !important;
    }

    .text-muted.text-1, .text-1 {
        color: #FF0025 !important;
    }

    .text-muted.text-2, .text-2 {
        color: #EB7D16 !important;
    }

    .text-muted.text-3, .text-3 {
        color: #048B52 !important;
    }

    .text-muted.text-4, .text-4 {
        color: #555555 !important;
    }

    .text-muted.text-5, .text-5 {
        color: #000000 !important;
    } 

    .border-1 {
        border: 1px solid #FF0025 !important;
    }

    .border-2 {
        border: 1px solid #EB7D16 !important;
    }

    .border-3 {
        border: 1px solid #048B52 !important;
    }

    .border-4 {
        border: 1px solid #555555 !important;
    }

    .border-5 {
        border: 1px solid #000000 !important;
    }

    .radio .bv-no-focus-ring{
        display: flex;
    }

    .radio .bv-no-focus-ring .custom-radio{
        padding-right: 30px;
    }
    
    td>li{
        list-style: none;
        font-size: 20px;
    }

    .table thead th{
        border: 0 !important;
    }

    .pagination{
        margin: 20px;
        display: flex;
        justify-content: center;
    }

    .page-link{
        margin: 3px;
        background: #D7DAE2;
        color: #707070;
    }

    .page-item.active .page-link{
        background: #707070;
        border-color: #707070;
    }

    .nova-solicitacao{
        top: 80px;
        right: 20px;
        width: 60px;
        height: 60px;
        background: var(--laranjado);
        border-radius: 50%;
        position: absolute;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        cursor: pointer;
    }

    .salvar-solicitacao{
        position: absolute;
        bottom: 30px;
        /* margin: auto; */
        width: 100%;
        display: flex;
        justify-content: center;
    }

</style>
