import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
	state: {
		loading       : false,
		sidebar       : false,
		auth          : false,
		usuario       : JSON.parse(localStorage.getItem('usuario')),
		shapes        : false,
		configuracoes : {}
	},
	mutations: {
		changeLoading(state, loading) {
			state.loading = loading
		},
		changeSidebar(state, sidebar) {
			state.sidebar = sidebar
		},
		changeAuth(state, auth) {
			state.auth = auth
		},
		changeUsuario(state, usuario) {
			state.usuario = usuario
		},
		changeShapes(state, shapes) {
			state.shapes = shapes
		},
		changeConfiguracoes(state, configuracoes) {
			state.configuracoes = configuracoes
		}
	},
	getters: {
		loading       : state => state.loading,
		sidebar       : state => state.sidebar,
		auth          : state => state.auth,
		usuario       : state => state.usuario,
		shapes        : state => state.shapes,
		configuracoes : state => state.configuracoes
	}
})