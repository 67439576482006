<template>
	<div id="relatorios">
		<div class="container mt-5 pt-5">
			<div class="row mt-4">
				<div class="col-sm-3 sidebar-filter">
					<div class="col-sm-3">
						<h5>Filtros</h5>
						<div class="card">
							<div class="card-body">
								<div class="form-group" v-if="$store.getters.usuario.cod_perfil == 4">
									<label for="">Filtrar por RA:</label>
									<v-select v-model="filtrosNaturais.ra" :reduce="option => option.cod" :options="ra" :multiple="true"></v-select>
								</div>
								<div class="form-group">
									<label for="">Filtrar por situação:</label>
									<v-select v-model="filtrosNaturais.status" :reduce="option => option.cod" :options="status" :multiple="true"></v-select>
								</div>
								<div class="form-group" v-if="$store.getters.usuario.cod_perfil == 4">
									<label for="">Filtrar por orgão:</label>
									<v-select v-model="filtrosNaturais.orgao" :reduce="option => option.cod" :options="orgao" :multiple="true"></v-select>
								</div>

								<div class="form-group">
									<label for="example-datepicker">Data inicial:</label>
									<flat-pickr v-model="filtrosNaturais.inicio" class="form-control" :config="config"></flat-pickr>
								</div>

								<div class="form-group">
									<label for="example-datepicker">Data final:</label>
									<flat-pickr v-model="filtrosNaturais.fim" class="form-control" :config="config"></flat-pickr>
								</div>
								
								<div class="row">
									<div class="col pr-1">
										<button class="btn btn-block btn-outline-info" v-on:click="filtrar">Filtrar <b-icon icon="filter"></b-icon></button>									
									</div>
									<div class="col pl-1">
										<button class="btn btn-block btn-outline-secondary" v-on:click="limpar">Limpar <b-icon icon="x-circle-fill"></b-icon></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-9">
					<h5>Solicitações</h5>
					<div class="card">
						<div class="card-body">
							<div class="text-right col mb-3">
								<button class="btn-outline-secondary" @click="print">
									<i class="fa fa-print" aria-hidden="true"></i> Imprimir
								</button>
							</div>
							<div id="print" class="row justify-content-center">
								<h2 class="text-center my-3">
									Relatório de solicitações
									<small style="font-size:40%;display: block">Gerada em {{ new Date() | moment("DD/MM/YYYY") }}</small>
								</h2>
								<div class="col-sm-12">
									<div class="table-responsive">
										<table class="table table-bordered table-striped table-hover">
											<thead>
												<tr>
													<th>#</th>
													<th>Situação</th>
													<th>RA</th>
													<th>Orgão</th>
													<th>Data</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(denuncia, key) in denuncias.rows" :key="key">
													<td v-html="key+1"></td>
													<td v-html="denuncia.status"></td>
													<td v-html="denuncia.ra"></td>
													<td v-html="denuncia.orgao"></td>
													<td>{{ denuncia.created | moment("DD/MM/YYYY") }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="text-right col mb-3" v-if="denuncias.rows.length > 0">
								<button class="btn-outline-secondary" @click="print">
									<i class="fa fa-print" aria-hidden="true"></i> Imprimir
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import flatPickr from 'vue-flatpickr-component';
	import 'flatpickr/dist/flatpickr.css';
	import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
	export default {
		name: "Relatorios",
		components: {
			flatPickr
		},
		data(){
			return {
				denuncias: [],
				ra: [],
				status: [],
				orgao: [],
				config: {
					wrap: true,
					altFormat: 'd/m/Y',
					altInput: true,
					dateFormat: 'Y-m-d',
					locale: Portuguese,
				},
				filtrosNaturais: {
					all: true,
					ra: 0,
					status: 0,
					orgao: 0
				},
				output: null
			}
		},
		created(){
			if (this.$store.getters.usuario.cod_perfil != 4) {
                if (this.$store.getters.usuario.cod_perfil == 1) {
                    this.filtrosNaturais = Object.assign(this.filtrosNaturais, {cod_ra: this.$store.getters.usuario.codigo});
                }
                if (this.$store.getters.usuario.cod_perfil == 2) {
                    this.filtrosNaturais = Object.assign(this.filtrosNaturais, {cod_orgao: this.$store.getters.usuario.codigo});
                }
            }
			this.$http.post('buscaDenunciaWeb.php', this.filtrosNaturais).then(
				response => {
					this.sem_resultados = 'Sem resultados'
					this.denuncias = response.data 
				}
            )

            this.$http.post('buscaFiltros.php').then(
				response => {
					response.data.ra.forEach((item) => {
                        this.ra.push({
                            cod: item.cod_ra,
                            label: item.nome
                        })
                    });
                    response.data.status.forEach((item) => {
                        this.status.push({
                            cod: item.cod_status,
                            label: item.dsc_status
                        })
                    });
                    response.data.orgao.forEach((item) => {
                        this.orgao.push({
                            cod: item.cod_orgao,
                            label: item.descricao
                        })
                    });
				}
            )
		},
		methods: {
			filtrar(){

				this.denuncias.rows = {}
				this.$http.post('buscaDenunciaWeb.php', {filtros: this.filtrosNaturais}).then(
					response => {
						this.denuncias = response.data
						this.loading = false
					}
				)
			},
			limpar(){
				this.loading = true
				this.denuncias.rows = {}
				this.filtros = {
					ra: 0,
					status: 0,
					orgao: 0
				}
				this.$http.post('buscaDenunciaWeb.php').then(
					response => {
						this.denuncias = response.data
						this.loading   = false
					}
				)
			},
			print () {
				this.$htmlToPaper('print');
			}
		}
	}
</script>

<style scoped>
	table{
		font-size: 11px;
	}
	#relatorios{
		margin-left: 3.5rem;
	}
	#relatorios .container{
		margin: 0 !important;
		max-width: 100%;
	}

	.sidebar-filter{
		position: relative;
	}

	.sidebar-filter>div{
		position: fixed;
		padding: 0;
		padding-right: 30px;
	}
	.hide-sidebar{
		position: absolute;
		right: 0;
	}
</style>